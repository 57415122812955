// More
// ====

[data-layout='more'] {
  @include above('page-break') {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: size('gutter');
    max-width: size('page', ('times': 2.5));
  }
}

.more {
  background-color: var-color('background');
  background-clip: padding-box;
  border: size('half-shim', 'px') solid transparent;
  border-width: size('half-shim', 'px') 0;

  @include above('page-break') {
    padding: var(--margin) calc(var(--margin) * 1.5);

    h3,
    p {
      margin: 0;
    }

    &:nth-child(1) {
      border-bottom-color: var-color('removed-fg');
      border-top-color: var-color('removed-bg');
      border-width: size('half-shim', 'px');
    }

    &:nth-child(2) {
      border-bottom-color: var-color('removed-bg');
      border-top-color: var-color('removed-fg');
      border-width: size('half-shim', 'px');
    }

    &:nth-child(3) {
      border-bottom-color: var-color('removed-fg');
      border-top-color: var-color('removed-bg');
      border-width: size('half-shim', 'px');
    }

    &:nth-child(4) {
      border-bottom-color: var-color('removed-bg');
      border-top-color: var-color('removed-fg');
      border-width: size('half-shim', 'px');
    }
  }
}

.embed-container {
  height: 0;
  margin-top: size('shim');
  max-width: 100%;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
