// Fancy Title
// ===========

.fancy-title {
  margin-bottom: size('gutter');

  span {
    display: inline-block;
  }

  .and {
    color: var-color('action-hover');
    font-size: size('large', ('times': 2.5));
    margin: negative('shim') 0;
    vertical-align: middle;
  }
}

