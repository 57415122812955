// Type
// ====

:root {
  @include font-family('body');
  font-size: size('responsive');
  line-height: ratio('text-ratio');
}

h1,
h2,
h3 {
  @include font-family('alt');
}

h2 {
  font-size: size('xlarge');
  line-height: 1.2;
}

h3 {
  font-size: size('large');
}

p {
  margin-bottom: size('gutter');
}


// Links
// -----

a {
  #{$link} {
    color: var-color('action');
  }

  #{$focus} {
    color: var-color('action-hover');
  }
}


// Special
// -------

.amp {
  @include font-family('amp');
  font-style: italic;
  font-weight: normal;
}

.is-hidden {
  @include is-hidden;
}
