// Grid
// ====

[data-layout='grid'] {
  --margin: #{size('gutter')};
  --bleed: calc(0 * 1px - var(--margin, #{size('shim')}));
  display: grid;
  grid-gap: size('double-gutter');
  grid-template: 'banner' auto
                 'main' auto
                 'graphic' auto
                 'more' auto
                 'footer' auto
               / minmax(0, size('page'));
  justify-content: center;
  justify-items: center;
  min-height: 100vh;
  overflow-x: hidden;
  padding: var(--margin);

  @include above('page-break') {
    grid-template: 'banner graphic' auto
                   'main   graphic' auto
                   'more   more' auto
                   'footer nope' auto
                  / minmax(size('page'), 1fr) minmax(0, 1fr);
  }
}

[data-layout="banner"],
[data-layout="main"],
[data-layout="more"],
[data-layout="footer"] {
  width: 100%;
  max-width: size('page');
}

[data-layout="banner"] {
  grid-area: banner;
}

[data-layout="main"] {
  align-self: center;
  grid-area: main;

  @include above('page-break') {
    padding: size('spacer') 0;
  }
}

[data-layout="more"] {
  grid-area: more;
}

[data-layout='dot-grid'] {
  --position: center;
  background-color: var-color('graphic-base');
  background-image: radial-gradient(
    circle at var(--position),
    var-color('graphic-glow'),
    var-color('graphic-base')
  );
  grid-area: graphic;
  justify-self: stretch;
  margin: 0 calc(50% - 50vw);

  @include above('page-break') {
    --position: 0 50vh;
    grid-area: 1 / graphic / -1;
    margin: var(--bleed) var(--bleed) var(--bleed) 0;
  }
}

[data-layout="graphic"] {
  align-items: center;
  display: flex;
  grid-area: graphic;
  justify-self: start;
  overflow: hidden;
  padding: var(--margin) 0;
  position: relative;
  width: 100%;

  @include above('page-break') {
    margin: 0 0 0 var(--bleed);
    padding-right: var(--margin);
    width: calc(100% + var(--margin) * 2);
  }
}

[data-layout="footer"] {
  grid-area: footer;

  @include above('page-break') {
    margin-top: size('spacer');
  }
}
