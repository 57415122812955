// Forms
// =====


form {
  @include font-family('alt');
  margin: size('gutter') 0;
}


// Buttons
// -------

[type='submit'] {
  @include var-contrasted('action-bright');
  font-size: size('xsmall');
  font-weight: bold;
  padding: size('shim');
  text-transform: uppercase;

  #{$focus} {
    @include var-contrasted('action-hover');
  }
}


// Labels
// ------

label {
  color: var-color('text-light');
  display: block;
  font-size: size('xsmall');
  font-weight: bold;
  padding: size('quarter-shim') size('shim');
  text-transform: uppercase;
}


// Inputs
// ------

[type='text'],
[type='email'] {
  border: 1px solid var-color('border');
  color: var-color('action-hover');
  padding: size('shim');
}


// Email Signup
// ============

.mc-field-group {
  display: grid;
  grid-template: 'label label' auto
                 'input button' auto
               / minmax(50%, 1fr) auto;
}

[for='mce-EMAIL'] {
  grid-area: label;
}

[id='mce-EMAIL'] {
  border-right: 0;
  grid-area: input;
}

[id='mc-embedded-subscribe'] {
  grid-area: button;
}
