// Banner
// ======

[data-icon='logo'] {
  width: calc(8em + 1vw);
}

[data-logo='odd'] {
  fill: var-color('action-hover');
}

[data-logo='books'] {
  fill: var-color('action');
}
