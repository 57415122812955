html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  text-size-adjust: 100%;
}

main {
  display: block;
}

details {
  display: block;
}

pre {
  overflow: auto;
}

progress {
  display: inline-block;
}

summary {
  display: block;
}

template {
  display: none;
}

*,
::before,
::after {
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
}

* {
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

audio :not([controls]) {
  display: none;
}

[aria-busy=true] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled] {
  cursor: default;
}

[hidden] {
  display: none;
}

button {
  overflow: visible;
}

input {
  border-radius: 0;
  line-height: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  appearance: button;
  cursor: pointer;
}
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border: 0 none !important;
  padding: 0 !important;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number] {
  width: auto;
}

[type=search] {
  appearance: textfield;
}

button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
}

textarea {
  overflow: auto;
  resize: vertical;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

audio,
canvas,
iframe,
img,
svg,
video {
  max-width: 100%;
  vertical-align: middle;
}

a {
  background-color: transparent;
}
a:active, a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

code,
kbd,
pre,
samp {
  font-family: monospace;
  font-size: inherit;
}

dfn {
  font-style: italic;
}

nav ol,
nav ul {
  list-style: none;
}

small {
  font-size: 0.75em;
}

sub,
sup {
  font-size: 0.75em;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

[data-theme=light] {
  --color-blue-light: #49a6c5;
  --color-blue-dark: #215869;
  --color-pink-light: #ff57ab;
  --color-pink-dark: #b10d65;
  --color-gray-dark: #3f3f40;
  --color-gray: #4b4c4d;
  --color-gray-light: #a3a4a6;
  --color-contrast-light: #fafcff;
  --color-contrast-dark: #13323c;
  --color-added-dark: #3d6644;
  --color-added-light: #d8eedb;
  --color-changed-dark: #3e6a79;
  --color-changed-light: #e0f7ff;
  --color-removed-dark: #946ca7;
  --color-removed-light: #d0b9da;
  --color-background:var(--color-contrast-light, #fafcff);
  --color-text:var(--color-contrast-dark, #13323c);
  --color-text-light:var(--color-gray-dark, #3f3f40);
  --color-border:var(--color-gray, #4b4c4d);
  --color-border-light:var(--color-gray-light, #a3a4a6);
  --color-action: #266478;
  --color-action-hover:var(--color-pink-dark, #b10d65);
  --color-action-bright: #e60073;
  --color-graphic-glow:var(--color-blue-light, #49a6c5);
  --color-graphic-base:var(--color-blue-dark, #215869);
  --color-added-fg:var(--color-added-dark, #3d6644);
  --color-added-bg:var(--color-added-light, #d8eedb);
  --color-changed-fg:var(--color-changed-dark, #3e6a79);
  --color-changed-bg:var(--color-changed-light, #e0f7ff);
  --color-removed-fg:var(--color-removed-dark, #946ca7);
  --color-removed-bg:var(--color-removed-light, #d0b9da);
}
[data-theme=light] [data-theme=invert] {
  --color-background:var(--color-contrast-dark, #13323c);
  --color-text:var(--color-contrast-light, #fafcff);
  --color-text-light:var(--color-gray-light, #a3a4a6);
  --color-border:var(--color-gray-light, #a3a4a6);
  --color-border-light:var(--color-gray, #4b4c4d);
  --color-action:var(--color-blue-light, #49a6c5);
  --color-action-hover:var(--color-pink-light, #ff57ab);
  --color-action-bright: #e60073;
  --color-graphic-glow:var(--color-removed-dark, #946ca7);
  --color-graphic-base:var(--color-removed-light, #d0b9da);
  --color-added-fg:var(--color-added-light, #d8eedb);
  --color-added-bg:var(--color-added-dark, #3d6644);
  --color-changed-fg:var(--color-changed-light, #e0f7ff);
  --color-changed-bg:var(--color-changed-dark, #3e6a79);
  --color-removed-fg:var(--color-removed-light, #d0b9da);
  --color-removed-bg:var(--color-removed-dark, #946ca7);
}

[data-theme=dark] {
  --color-blue-light: #49a6c5;
  --color-blue-dark: #215869;
  --color-pink-light: #ff57ab;
  --color-pink-dark: #b10d65;
  --color-gray-dark: #3f3f40;
  --color-gray: #4b4c4d;
  --color-gray-light: #a3a4a6;
  --color-contrast-light: #fafcff;
  --color-contrast-dark: #13323c;
  --color-added-dark: #3d6644;
  --color-added-light: #d8eedb;
  --color-changed-dark: #3e6a79;
  --color-changed-light: #e0f7ff;
  --color-removed-dark: #946ca7;
  --color-removed-light: #d0b9da;
  --color-background:var(--color-contrast-dark, #13323c);
  --color-text:var(--color-contrast-light, #fafcff);
  --color-text-light:var(--color-gray-light, #a3a4a6);
  --color-border:var(--color-gray-light, #a3a4a6);
  --color-border-light:var(--color-gray, #4b4c4d);
  --color-action:var(--color-blue-light, #49a6c5);
  --color-action-hover:var(--color-pink-light, #ff57ab);
  --color-action-bright: #e60073;
  --color-graphic-glow:var(--color-removed-dark, #946ca7);
  --color-graphic-base:var(--color-removed-light, #d0b9da);
  --color-added-fg:var(--color-added-light, #d8eedb);
  --color-added-bg:var(--color-added-dark, #3d6644);
  --color-changed-fg:var(--color-changed-light, #e0f7ff);
  --color-changed-bg:var(--color-changed-dark, #3e6a79);
  --color-removed-fg:var(--color-removed-light, #d0b9da);
  --color-removed-bg:var(--color-removed-dark, #946ca7);
}
[data-theme=dark] [data-theme=invert] {
  --color-background:var(--color-contrast-light, #fafcff);
  --color-text:var(--color-contrast-dark, #13323c);
  --color-text-light:var(--color-gray-dark, #3f3f40);
  --color-border:var(--color-gray, #4b4c4d);
  --color-border-light:var(--color-gray-light, #a3a4a6);
  --color-action: #266478;
  --color-action-hover:var(--color-pink-dark, #b10d65);
  --color-action-bright: #e60073;
  --color-graphic-glow:var(--color-blue-light, #49a6c5);
  --color-graphic-base:var(--color-blue-dark, #215869);
  --color-added-fg:var(--color-added-dark, #3d6644);
  --color-added-bg:var(--color-added-light, #d8eedb);
  --color-changed-fg:var(--color-changed-dark, #3e6a79);
  --color-changed-bg:var(--color-changed-light, #e0f7ff);
  --color-removed-fg:var(--color-removed-dark, #946ca7);
  --color-removed-bg:var(--color-removed-light, #d0b9da);
}

[data-theme] {
  background-color: var(--color-background, #fafcff);
  color: var(--color-text, #13323c);
}

:root {
  font-family: freight-text-pro, Baskerville, Palatino, Cambria, Georgia, serif;
  font-size: calc(16px + 0.25vw);
  line-height: 1.6;
}

h1,
h2,
h3 {
  font-family: freight-sans-pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

h2 {
  font-size: calc(1.2649110641rem + 0.75vw);
  line-height: 1.2;
}

h3 {
  font-size: 1.2649110641rem;
}

p {
  margin-bottom: 1.6rem;
}

a:link, a:visited {
  color: var(--color-action, #266478);
}
a:hover, a:focus, a:active {
  color: var(--color-action-hover, #b10d65);
}

.amp {
  font-family: BaskervilleAmp, Baskerville, "Goudy Old Style", Palatino, "Book Antiqua", serif;
  font-style: italic;
  font-weight: normal;
}

.is-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(1px 1px 1px 1px);
  height: 1px;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  width: 1px;
}

[data-layout=grid] {
  --margin: 1.6rem;
  --bleed: calc(0 * 1px - var(--margin, 0.8rem));
  display: grid;
  grid-gap: 3.2rem;
  grid-template: "banner" auto "main" auto "graphic" auto "more" auto "footer" auto/minmax(0, 30rem);
  justify-content: center;
  justify-items: center;
  min-height: 100vh;
  overflow-x: hidden;
  padding: var(--margin);
}
@media (min-width: 60em) {
  [data-layout=grid] {
    grid-template: "banner graphic" auto "main   graphic" auto "more   more" auto "footer nope" auto/minmax(30rem, 1fr) minmax(0, 1fr);
  }
}

[data-layout=banner],
[data-layout=main],
[data-layout=more],
[data-layout=footer] {
  width: 100%;
  max-width: 30rem;
}

[data-layout=banner] {
  grid-area: banner;
}

[data-layout=main] {
  align-self: center;
  grid-area: main;
}
@media (min-width: 60em) {
  [data-layout=main] {
    padding: calc(3.2rem + 4vmin) 0;
  }
}

[data-layout=more] {
  grid-area: more;
}

[data-layout=dot-grid] {
  --position: center;
  background-color: var(--color-graphic-base, #215869);
  background-image: radial-gradient(circle at var(--position), var(--color-graphic-glow, #49a6c5), var(--color-graphic-base, #215869));
  grid-area: graphic;
  justify-self: stretch;
  margin: 0 calc(50% - 50vw);
}
@media (min-width: 60em) {
  [data-layout=dot-grid] {
    --position: 0 50vh;
    grid-area: 1/graphic/-1;
    margin: var(--bleed) var(--bleed) var(--bleed) 0;
  }
}

[data-layout=graphic] {
  align-items: center;
  display: flex;
  grid-area: graphic;
  justify-self: start;
  overflow: hidden;
  padding: var(--margin) 0;
  position: relative;
  width: 100%;
}
@media (min-width: 60em) {
  [data-layout=graphic] {
    margin: 0 0 0 var(--bleed);
    padding-right: var(--margin);
    width: calc(100% + var(--margin) * 2);
  }
}

[data-layout=footer] {
  grid-area: footer;
}
@media (min-width: 60em) {
  [data-layout=footer] {
    margin-top: calc(3.2rem + 4vmin);
  }
}

[data-icon=logo] {
  width: calc(8em + 1vw);
}

[data-logo=odd] {
  fill: var(--color-action-hover, #b10d65);
}

[data-logo=books] {
  fill: var(--color-action, #266478);
}

@media (min-width: 60em) {
  [data-layout=more] {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 1.6rem;
    max-width: 75rem;
  }
}

.more {
  background-color: var(--color-background, #fafcff);
  background-clip: padding-box;
  border: 6.4px solid transparent;
  border-width: 6.4px 0;
}
@media (min-width: 60em) {
  .more {
    padding: var(--margin) calc(var(--margin) * 1.5);
  }
  .more h3,
.more p {
    margin: 0;
  }
  .more:nth-child(1) {
    border-bottom-color: var(--color-removed-fg, #946ca7);
    border-top-color: var(--color-removed-bg, #d0b9da);
    border-width: 6.4px;
  }
  .more:nth-child(2) {
    border-bottom-color: var(--color-removed-bg, #d0b9da);
    border-top-color: var(--color-removed-fg, #946ca7);
    border-width: 6.4px;
  }
  .more:nth-child(3) {
    border-bottom-color: var(--color-removed-fg, #946ca7);
    border-top-color: var(--color-removed-bg, #d0b9da);
    border-width: 6.4px;
  }
  .more:nth-child(4) {
    border-bottom-color: var(--color-removed-bg, #d0b9da);
    border-top-color: var(--color-removed-fg, #946ca7);
    border-width: 6.4px;
  }
}

.embed-container {
  height: 0;
  margin-top: 0.8rem;
  max-width: 100%;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

[data-layout=footer] {
  font-family: freight-sans-pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.790569415rem;
  font-weight: bold;
}

.toggle-theme {
  background-color: var(--color-action, #266478);
  color: var(--color-contrast-light, #fafcff);
  margin: 0 0.8rem;
  padding: 0 0.8rem;
}
.toggle-theme:hover, .toggle-theme:focus, .toggle-theme:active {
  background-color: var(--color-action-hover, #b10d65);
  color: var(--color-contrast-light, #fafcff);
}

form {
  font-family: freight-sans-pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 1.6rem 0;
}

[type=submit] {
  background-color: var(--color-action-bright, #e60073);
  color: var(--color-contrast-light, #fafcff);
  font-size: 0.625rem;
  font-weight: bold;
  padding: 0.8rem;
  text-transform: uppercase;
}
[type=submit]:hover, [type=submit]:focus, [type=submit]:active {
  background-color: var(--color-action-hover, #b10d65);
  color: var(--color-contrast-light, #fafcff);
}

label {
  color: var(--color-text-light, #3f3f40);
  display: block;
  font-size: 0.625rem;
  font-weight: bold;
  padding: 0.2rem 0.8rem;
  text-transform: uppercase;
}

[type=text],
[type=email] {
  border: 1px solid var(--color-border, #4b4c4d);
  color: var(--color-action-hover, #b10d65);
  padding: 0.8rem;
}

.mc-field-group {
  display: grid;
  grid-template: "label label" auto "input button" auto/minmax(50%, 1fr) auto;
}

[for=mce-EMAIL] {
  grid-area: label;
}

[id=mce-EMAIL] {
  border-right: 0;
  grid-area: input;
}

[id=mc-embedded-subscribe] {
  grid-area: button;
}

.fancy-title {
  margin-bottom: 1.6rem;
}
.fancy-title span {
  display: inline-block;
}
.fancy-title .and {
  color: var(--color-action-hover, #b10d65);
  font-size: 3.1622776602rem;
  margin: -0.8rem 0;
  vertical-align: middle;
}

[data-layout=dot-grid] {
  align-items: center;
  display: grid;
  justify-items: center;
  padding-bottom: 1.6rem;
  position: relative;
  z-index: -1;
}

[data-dot] {
  --delay: -15s;
  --dot: var(--color-removed-fg, #946ca7);
  animation: blip 90s var(--delay, 0s) infinite ease;
  background: var(--dot);
  fill: var(--dot);
  opacity: 0;
}
[data-dot]:not(circle) {
  height: calc(3px + 0.5vmin);
  width: calc(3px + 0.5vmin);
  border-radius: 100%;
  grid-area: var(--area);
  transform: scale(var(--scale));
}

[data-dot="1"] {
  --delay: -15s;
  --dot: var(--color-removed-bg, #d0b9da);
}

[data-dot="2"] {
  --delay: -19s;
  --dot: var(--color-changed-bg, #e0f7ff);
}

[data-dot="3"] {
  --delay: -3s;
  --dot: var(--color-changed-fg, #3e6a79);
}

[data-dot="4"] {
  --delay: -28s;
}

[data-dot="5"] {
  --delay: -23s;
  --dot: var(--color-added-bg, #d8eedb);
}

@keyframes blip {
  12%, 37%, 95% {
    opacity: 0;
  }
  21%, 40%, 63% {
    opacity: 1;
  }
}
.graphic {
  flex: 0 1 40em;
}
@media (min-width: 60em) {
  .graphic {
    min-width: 25em;
  }
}
.graphic .pencil {
  fill: var(--color-background, #fafcff);
}
.graphic .page {
  fill: var(--color-background, #fafcff);
  stroke: var(--color-border-light, #a3a4a6);
  stroke-width: 1;
}
.graphic .text {
  opacity: 0.4;
  fill: var(--color-border-light, #a3a4a6);
}
.graphic .diff-ins {
  fill: var(--color-added-bg, #d8eedb);
}
.graphic .dif-mark {
  fill: var(--color-changed-bg, #e0f7ff);
}
.graphic .diff-del {
  fill: var(--color-removed-fg, #946ca7);
}
.graphic .tooltip {
  fill: var(--color-action-bright, #e60073);
}
.graphic .tiptext {
  fill: var(--color-contrast-light, #fafcff);
}